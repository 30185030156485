<template>
  <div class="d-flex justify-content-center">
    <b-form class="mt-5 mb-5 form-acc-info" @submit.prevent="updateInfoAccount">
      <b-row>
        <b-col cols="12">
          <b-form-group
            id="input-group-1"
            label="First name *"
            label-for="input-1"
            class="mb-3"
          >
            <b-form-input
              id="input-1"
              v-model="form.name"
              type="text"
              placeholder="Enter name"
              :class="{'is-invalid': $v.form.name.$error}"
            ></b-form-input>
            <span role="alert" class="invalid-feedback">
              <strong v-if="!$v.form.name.required">The name is required.</strong>
              <strong v-if="!$v.form.name.minLength">The name must have at least {{$v.form.name.$params.minLength.min}} letters.</strong>
              <strong v-if="!$v.form.name.maxLength">The name must be less than {{$v.form.name.$params.maxLength.max}} letters.</strong>
            </span>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            id="input-group-1"
            label="Last name *"
            label-for="input-2"
            class="mb-3"
          >
            <b-form-input
              id="input-2"
              v-model="form.last_name"
              type="text"
              placeholder="Enter Last name"
              :class="{'is-invalid': $v.form.last_name.$error}"
            ></b-form-input>
            <span role="alert" class="invalid-feedback">
              <strong v-if="!$v.form.last_name.required">The last name is required.</strong>
              <strong v-if="!$v.form.last_name.minLength">The last name must have at least {{$v.form.last_name.$params.minLength.min}} letters.</strong>
              <strong v-if="!$v.form.last_name.maxLength">The last name must be less than {{$v.form.last_name.$params.maxLength.max}} letters.</strong>
            </span>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            id="input-group-1"
            label="E-Mail Address *"
            label-for="input-3"
            class="mb-3"
          >
            <b-form-input
              id="input-3"
              v-model="form.email"
              type="text"
              placeholder="Enter E-Mail Address"
              :class="{'is-invalid': $v.form.email.$error || errorEmail}"
            ></b-form-input>
            <span role="alert" class="invalid-feedback">
              <strong v-if="!$v.form.email.required">The e-mail address is required</strong>
              <strong v-if="!$v.form.email.email">Invalid E-mail format</strong>
              <strong v-if="!$v.form.email.maxLength">The e-mail address must be less than {{$v.form.email.$params.maxLength.max}} letters</strong>
              <strong v-if="errorEmail">This email address has already been taken</strong>
            </span>
          </b-form-group>
        </b-col>
        <b-col class="d-flex justify-content-end">
          <b-button type="submit">Update Account Information</b-button>
        </b-col>
      </b-row>
    </b-form>
    <b-overlay :show="load" rounded="sm" no-wrap></b-overlay>
  </div>
</template>
<script>

import { required, email, minLength, maxLength} from 'vuelidate/lib/validators';

export default {
  name: 'Information_account',

  data() {
    return {
      form: {
        name: this.$session.get('userInfo').name,
        last_name: this.$session.get('userInfo').last_name,
        email: this.$session.get('userInfo').email
      },
      errorEmail: false,
      load: false,
    };
  },
  validations: {
    form: {
      name: { required, minLength: minLength(2), maxLength: maxLength(60) },
      last_name: { required, minLength: minLength(2), maxLength: maxLength(60) },
      email: { required, email, maxLength: maxLength(60) },
    }
  },
  methods: {
    updateInfoAccount(){
      this.$v.form.$reset();
      this.$v.form.$touch();
      if (!this.$v.form.$invalid) {
        this.load = true;
        this.$http.post('updateInfoAccount', this.form, { headers: { Authorization: `Bearer ${this.$session.get('jwt')}` } }).then((response) => {
          if (response.status == 200) {
            this.$session.set('userInfo', response.body.data);
            this.$notify({ group: 'app', text: response.body.message, type: 'success' });
            this.$parent.$parent.$parent.$parent.getUserInfo();
          }
          this.load = false;
        }, (error) => {
          if (error.status == 500) {
            this.$notify({ group: 'app', text: 'An error has occurred, please try again.', type: 'error' });
          }
          if (error.status === 400) {
            if (error.body.response == 'error_email') {
              this.errorEmail = true;
            }else{
              this.$notify({ group: 'app', text: error.body.message, type: 'warn' });
            }
          }
          this.load = false;
        })
      }
    }
  },
};
</script>
