<template>
  <b-container class="p-3x">
    <b-card class="bs-br">
      <b-tabs content-class="mt-3">
      <b-tab title="Information" active>
        <InformationAccount/>
      </b-tab>
      <b-tab title="Change Password">
        <ChangePassword/>
      </b-tab>
    </b-tabs>
    </b-card>
  </b-container>
</template>
<script>

import InformationAccount from '@/views/Dashboard/Account/InformationAccount.vue';
import ChangePassword from '@/views/Dashboard/Account/ChangePassword.vue';

export default {
  name: 'Account',
  components: {
    InformationAccount,
    ChangePassword
  },
  metaInfo(){
    return {
      title: 'Account Information',
      titleTemplate: '%s | Do Genius On'
    }
  },
};
</script>
