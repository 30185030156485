<template>
  <div class="d-flex justify-content-center">
    <b-form class="mt-5 mb-5 form-acc-info" @submit.prevent="updatePassword">
      <b-row>
        <b-col cols="12" xl="12" lg="12" md="12" sm="12">
          <b-form-group
            id="input-group-2"
            label="Current password *"
            label-for="input-4"
            class="mb-3"
          >
            <b-form-input
              id="input-4"
              v-model="form.current_password"
              type="password"
              placeholder="Enter current password"
              :class="{'is-invalid': $v.form.current_password.$error || errorPassword}"
            ></b-form-input>
            <span role="alert" class="invalid-feedback">
              <strong v-if="!$v.form.current_password.required">The current password is required</strong>
              <strong v-if="errorPassword">Wrong current password</strong>
            </span>
          </b-form-group>
        </b-col>
        <b-col cols="12" xl="12" lg="12" md="12" sm="12">
          <b-form-group
            id="input-group-2"
            label="New password *"
            label-for="input-5"
            class="mb-3"
          >
            <b-form-input
              id="input-5"
              v-model="form.password"
              type="password"
              placeholder="Enter new password"
              :class="{'is-invalid': $v.form.password.$error || (!checkPassword.success && form.password != '' && form.password != null)}"
            ></b-form-input>
            <span role="alert" class="invalid-feedback">
              <strong v-if="!$v.form.password.required">The password is required</strong>
            </span>
            <span class="validation_password mt-1" v-if="form.password != '' && form.password != null">
              <span class="validation_password--text" :class="[checkPassword.level]">
                {{checkPassword.title}}
              </span>
              <span class="validation_password--description">
                {{checkPassword.description}}
              </span>
            </span>
          </b-form-group>
        </b-col>
        <b-col cols="12" xl="12" lg="12" md="12" sm="12">
          <b-form-group
            id="input-group-2"
            label="Confirm new password *"
            label-for="input-6"
            class="mb-3"
          >
            <b-form-input
              id="input-6"
              v-model="form.password_confirmation"
              type="password"
              placeholder="Enter confirm new password"
              :class="{'is-invalid': $v.form.password_confirmation.$error}"
            ></b-form-input>
            <span role="alert" class="invalid-feedback">
              <strong v-if="!$v.form.password_confirmation.required">The confirm new password is required</strong>
              <strong v-if="!$v.form.password_confirmation.sameAsPassword && $v.form.password_confirmation.required">The password confirmation does not match</strong>
            </span>
          </b-form-group>
        </b-col>
        <b-col class="d-flex justify-content-end">
          <b-button type="submit">Update Password</b-button>
        </b-col>
      </b-row>
    </b-form>
    <b-overlay :show="load" rounded="sm" no-wrap></b-overlay>
  </div>
</template>
<script>

import { required, minLength, maxLength, sameAs} from 'vuelidate/lib/validators';

export default {
  name: 'Information_account',

  data() {
    return {
      form: {
        current_password: null,
        password: null,
        password_confirmation: null
      },
      errorPassword: false,
      load: false
    };
  },
  validations: {
    form: {
      current_password: { required },
      password: {
        required,
        minLength: minLength(8),
        maxLength: maxLength(60),
        isValid(value){
          return this.checkLevelPassword(value).success;
        }
      },
      password_confirmation: {
        required,
        sameAsPassword: sameAs('password')
      },
    }
  },
  computed: {
    checkPassword(){
      return this.checkLevelPassword(this.form.password);
    }
  },
  methods: {
    updatePassword(){
      this.$v.form.$reset();
      this.$v.form.$touch();
      if (!this.$v.form.$invalid) {
        this.load = true;
        this.$http.post('reset_password', this.form , { headers: { Authorization: `Bearer ${this.$session.get('jwt')}` } }).then((response) => {
          if (response.status === 200) {
            this.$notify({ group: 'app', text: response.body.message, type: 'success' });
          }
          this.load = false;
        }, (error) => {
          if (error.status == 400) {
            if (error.body.response == 'wrong_current_password') {
              this.errorPassword = true;
            }else{
              this.$notify({ group: 'app', text: error.body.message, type: 'warn' });
            }
          }
          if (error.status === 500) {
            this.$notify({ group: 'app', text: 'An error occurred while trying to change the password. Try again.', type: 'error' });
          }
          this.load = false;
        })
      }
    }
  },
};
</script>
